let Credentials = require('./oa_credentials.json');
let sessionUToken = "";
let vers = "lctr";
if(window.location.hostname === "novaio.academy" || window.location.hostname === "www.novaio.academy") { vers = "lvtr"; } 
// if(localStorage.getItem('google_id') !== null) { sessionUToken = localStorage.getItem('google_id'); }
if(localStorage.getItem('doc_id') !== null && localStorage.getItem('doc_id') !== undefined) { sessionUToken = localStorage.getItem('doc_id'); }
module.exports = global.config = {
    /* #### LIVE VERSION ####### */
    urlLink: 'https://us-central1-ps-product-internalportal-api.cloudfunctions.net/api',
    clientLoginId: "748862631768-6b6mdqdpi8bomjhe232sm83205qrc8cq.apps.googleusercontent.com", 
    xenditKey: 'xnd_public_production_xv6wQuc6XyPwuahcJWyxotlqBCyV8fkXFXv3RqFQ9wuOU79PYunFVnzdLdMqETb',
    stripeKey: 'pk_live_4BCPwPEqHS0d3VKKa9kGv8NC',

    /* #### DEVELOPMENT VERSION ####### */
    // urlLink: 'http://localhost:4001',
    // clientLoginId: "4115767564-q28j63gonn7lcege7rt6spj3ncol31c9.apps.googleusercontent.com", 
    // xenditKey: 'xnd_public_development_LSJ98v4cd0HnwdwtGdBgbDVM4mUSp2HUZUGFz2GzMO4zDdry0gURMDMzbQSFXRJf',
    // stripeKey:'sk_test_O0a7CIvh87SI3s2eYV0dWYCW',

    /* #### GENERAL ####### */
    authBearerKey: sessionUToken+"##"+Credentials.web.client_secret+"##"+vers,
    kindcode: [
        "31G785","98U109","56T428","78Y851","28R393","93I200","52T892","62F210","81J037","73P159",
        "18J332","74G679","52K024","93Y210","28H193","53J268","45K027","82Y167","14K330","73M865",
        "26K321","94H190","85E180","06Y478","48B217","27S509","36V185","25M411","50P271","10K732",
        "75W369","88L352","17U196","44P737","61L385","38S299","66F165","34Q284","77W190","2Y8KH0",
        "90K114","","","","","52I768","J3R201"
    ],
    subscribePlan: [
        {name:["bulan","month"],period:1,type:"Monthly",price:[30000,2],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]},
        {name:["tahun","year"],period:1,type:"Yearly",price:[300000,20],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]}
    ],
    userLevel: ["Admin", "User"],
    subscribeMemberStatus: ["No Subscribe","On Process","Subscribe","Expired"],
    subscribeStatus: ["New Request","Active","Expired","Cancel"],
    storageUrl: 'https://storage.googleapis.com/ps-product-internalportal-api.appspot.com/',
    
    // subscribePlan: [
    //     {name:["bulan","month"],period:1,type:"month",price:[30000,2],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]},
    //     {name:["tahun","year"],period:1,type:"year",price:[300000,20],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]}
    // ],

    // xenditKey: 'xnd_public_production_xv6wQuc6XyPwuahcJWyxotlqBCyV8fkXFXv3RqFQ9wuOU79PYunFVnzdLdMqETb',
    // stripeKey: 'sk_test_51MTeRJCznuqE7u9MjswPiYggSUukiBKBSe4aKvu0kwtnR3toLmO9imulWYFGWbVLBIaLEwlt5oM6neVhL8E0n83600Hyu5MK24',
    // stripeKey: 'pk_live_4BCPwPEqHS0d3VKKa9kGv8NC',
    // stripeKey:'sk_test_O0a7CIvh87SI3s2eYV0dWYCW',
}