import React, { Component } from 'react';
import Moment from 'moment';
import Axios from 'axios';
import { Container, Tab, Nav, Form, Button, Badge } from 'react-bootstrap';
import { FaFileAlt, FaDownload, FaArrowRight } from 'react-icons/fa';
import { GetText, MD5 } from '../helper';
import '../config/global';
import defPicProfile from '../img/pic-profile-sq.gif';


const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[36];

export default class Account extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      memberId: localStorage.getItem('doc_id'),
      memberEmail: localStorage.getItem('email'),
      memberPicture: defPicProfile,
      member: [],
      subscriptionList: [],
      purchaseList: [],
      enrollList: [],
      certificateList: [],
      inputName: '',
      inputPhone: '',
      inputLanguage: '',
      inputPicture: null,
      inputPasswordCurrent: '',
      inputPasswordNew: '',
      inputPasswordConfirm: '',
      hasPassword: false,
      tabKey: '',
      tabPassword: 0,
      form_error: [],
      form_success: []
    }
    this.inputName = this.inputName.bind(this);
    this.inputPhone = this.inputPhone.bind(this);
    this.inputPhoto = this.inputPhoto.bind(this);
    this.inputLanguage = this.inputLanguage.bind(this);
    this.inputPasswordCurrent = this.inputPasswordCurrent.bind(this);
    this.inputPasswordNew = this.inputPasswordNew.bind(this);
    this.inputPasswordConfirm = this.inputPasswordConfirm.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.setTab = this.setTab.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  componentDidMount() {
    fetch(global.config.urlLink+"/traininguserview/" + this.state.memberId, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey
      }
    })
    .then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong.');
      }
    })
    .then(resultJson => {
      var content = resultJson.values[0].content[0].maincontent;
      var mFullName = content.memberName; if(mFullName === undefined) { mFullName = ""; }
      var mPhone = content.memberPhone; if(mPhone === undefined) { mPhone = ""; }
      var mLanguage = content.memberLanguage; if(mLanguage === undefined) { mLanguage = ""; }
      var mPicture = content.memberPicture; if(mPicture === undefined || mPicture === "") { mPicture = defPicProfile; }
      
      var subscriptionList = [];
      if(resultJson.values[0].scontent.length > 0) {
        subscriptionList = resultJson.values[0].scontent.sort((b, a) => a.subcontent.requestSubscribeDate._seconds - b.subcontent.requestSubscribeDate._seconds)
      }
      this.setState({
        inputName: mFullName,
        inputPhone: mPhone,
        inputLanguage: mLanguage,
        inputPicture: mPicture,
        memberPicture: mPicture,
        member : resultJson.values[0].content[0].maincontent,
        subscriptionList: subscriptionList,
        purchaseList: resultJson.values[0].scontent2,
        enrollList: resultJson.values[0].scontent3,
        certificateList: resultJson.values[0].scontent4,
        hasPassword: resultJson.values[0].content[0].maincontent.userPassword === "" ? false : true
      });
      
    });
  }

  inputName(event) {
    var error = [];
    if (event.target.value.length > 80) {
      document.getElementById('submit').setAttribute('disabled', 'true');
      error.push('inputNameMax');
    } else {
      document.getElementById('submit').removeAttribute('disabled');
		}
    this.setState({ 
      inputName: event.target.value,
      form_error: error
    });
  }

  inputPhone(event) {
    this.setState({ 
      inputPhone: event.target.value,
      form_error: []
    });
  }

  inputLanguage(event) {
    this.setState({ 
      inputLanguage: event.target.value,
      form_error: []
    });
  }

  inputPhoto(event) {
    if (event.target.files[0] !== null) {
      var value = event.target.files[0];
      if (value.type.indexOf('image') > -1) {
        this.setState({
          inputPicture: value,
          form_error: []
        }, () => {
          document.getElementById('input-photo').innerText = this.state.inputPicture.name;
        });
      }
    }
  }
  inputPasswordCurrent(event) {
    this.setState({ 
      inputPasswordCurrent: event.target.value,
      form_error: [],
      form_success: []
    });
  }

  inputPasswordNew(event) {
    var error = [];
    if (event.target.value.length < 6) {
      document.getElementById('submit').setAttribute('disabled', 'true');
      error.push('length');
    } else {
      document.getElementById('submit').removeAttribute('disabled');
    }
    this.setState({ 
      inputPasswordNew: event.target.value,
      form_error: error,
      form_success: []
    });
  }

  inputPasswordConfirm(event) {
    var error = [];
    if (this.state.inputPasswordNew !== event.target.value) {
      document.getElementById('submit').setAttribute('disabled', 'true');
      error.push('notsame');
    } else {
      document.getElementById('submit').removeAttribute('disabled');
    }
    this.setState({ 
      inputPasswordConfirm: event.target.value,
      form_error: error,
      form_success: []
    });
  }

  updatePassword(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (this.state.hasPassword === true) {
      if (this.state.inputPasswordCurrent === '') {
        error.push('inputPasswordCurrent');
      }
    }
    if (this.state.inputPasswordNew === '') {
      error.push('inputPasswordNew');
    } else if (this.state.inputPasswordNew.length < 6) {
      error.push('length');
    } else if (this.state.inputPasswordNew === this.state.inputPasswordCurrent) {
      error.push('same');
    }
    if (this.state.inputPasswordConfirm === '') {
      error.push('inputPasswordConfirm');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = 'Update';
      document.getElementById('submit').removeAttribute('disabled');
      return;
    } else {
        var dataText = '';
        if (this.state.hasPassword === true) {
          dataText = '{"user_email":"' + this.state.memberEmail + '","userfullname":"' + this.state.member.memberName + '","user_username":"' + this.state.member.userName + '","user_password_current":"' + MD5(this.state.inputPasswordCurrent) + '","user_password_new":"' + MD5(this.state.inputPasswordNew) + '"}';
        } else {
          dataText = '{"user_email":"' + this.state.memberEmail + '","userfullname":"' + this.state.member.memberName + '","user_username":"' + this.state.member.userName + '","user_password_current":"","user_password_new":"' + MD5(this.state.inputPasswordNew) + '"}';
        }
        var dataSend = JSON.parse(dataText);

        fetch(global.config.urlLink+"/passwordedit", {
          method: "POST",
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'Access-Control-Allow-Origin': "*",
            'Authorization': authKey2
          },
          body: JSON.stringify(dataSend),
        })
        .then((result) => {
          if (result.status === 200) {
            return result.json();
          } else {
            console.log("Something happened wrong.");
          }
        })
        .then((resultJson) => {
          document.getElementById('submit').innerText = 'Update';
          document.getElementById('submit').removeAttribute('disabled');
          var success = [];
          var error = [];
          if (resultJson.values[0].error === false) {
            success.push('updated');
            this.setState({
              inputPasswordCurrent: '',
              inputPasswordNew: '',
              inputPasswordConfirm: '',
              hasPassword: true,
              form_success: success
            });
          } else {
            error.push('invalid');
            this.setState({
              form_error: error
            });
          }
        })
        .catch((err) => {
          alert(err);
          window.location.href = "/" + this.state.redirect;
        });
    }
  }
  updateProfile(event) {
    event.preventDefault();
    document.getElementById('submit').innerText = 'Loading...';
    document.getElementById('submit').setAttribute('disabled', 'true');

    var error = [];
    if (!(this.state.inputName)) {
      error.push('inputName');
    }
    if (!(this.state.inputPhone)) {
      error.push('inputPhone');
    }
    if (!(this.state.inputLanguage)) {
      error.push('inputLanguage');
    }
    if (error.length) {
      this.setState({
        form_error: error
      });
      document.getElementById('submit').innerText = 'Update';
      document.getElementById('submit').removeAttribute('disabled');
      return;
    }

    const data = new FormData();
    data.append('file', this.state.inputPicture);
    data.append('source', 'profile');
    Axios.post(global.config.urlLink+"/uploadprofilephoto", 
      data, { 
        headers: { 
          'content-type': 'multipart/form-data',
          'Authorization': authKey2
        }
      }
    )
    .then(result => {
      var photo = result.data.status === '400' ? this.state.member.memberPicture : result.data.values;
      var dataText = '{"memberdoc_id":"' + localStorage.getItem('doc_id') + '","member_name":"' + this.state.inputName + '","member_phone":"' + this.state.inputPhone + '","member_picture":"' + photo + '","member_language":"' + this.state.inputLanguage +'"}';
      var dataSend = JSON.parse(dataText);
      
      fetch(global.config.urlLink+"/trainingupdateprofile", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey2
        }, 
        body : JSON.stringify(dataSend)
      })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        } else {
          console.log('Something happened wrong.');
        }
      }).then(resultJson => {
        localStorage.setItem('language', this.state.inputLanguage);
        window.location.reload();
      });
    });
  }

  setTab() {
    var url = window.location.href;
    var tabKey = url.substring(url.lastIndexOf('/') + 1);

    return tabKey ? tabKey : 'account';
  }
  togglePassword() {
    if (this.state.tabPassword === 0) {
      this.setState({
        tabPassword: 1,
      });
    } else {
      this.setState({
        tabPassword: 0,
      });
    }
  }
  render() {    
    return (
      <div className="profile">
        <Container>
          <div className="row py-5 mb-5">
            <Tab.Container defaultActiveKey={this.setTab}>
              <div className="col-md-4">
                <div className="text-center">
                  <img className="wh-180 mb-4" src={this.state.memberPicture} alt="Avatar"/>
                  <h5><strong>{this.state.member.memberName}</strong></h5>
                  <h6 className="mb-5 text-secondary">{this.state.member.memberEmail}</h6>
                </div>
                <Nav variant="pills" className="flex-column pills-scroll mt-5 mb-5 mb-md-0">
                  <Nav.Item><Nav.Link eventKey="account">{GetText() ? "Akun" : "Account"}</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="subscription">{GetText() ? "Berlangganan" : "Subscription"}</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="mylibraries">{GetText() ? "Library" : "My Library"}</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="mycourses">{GetText() ? "Workshop" : "My Workshops"}</Nav.Link></Nav.Item>
                  <Nav.Item><Nav.Link eventKey="mycertificates">{GetText() ? "Sertifikat" : "My Certificate"}</Nav.Link></Nav.Item>
                </Nav>
              </div>
              <div className="col-md-8 p-4 bg-light">
                <Tab.Content>
                <Tab.Pane eventKey="account">
                    <div className="row">
                      <div className="col-6">
                        <h3 className="mb-4">{GetText() ? "Akun" : "Account"}</h3>
                      </div>
                      <div className="col-6 text-right">
                        {
                          this.state.tabPassword === 0 ? (
                            <div className="link-more" onClick={this.togglePassword}>{GetText() ? "Ubah Password" : "Change Password"} <FaArrowRight/></div>
                          ) : (
                            <div className="link-more" onClick={this.togglePassword}>{GetText() ? "Pengaturan Akun" : "Account Settings"} <FaArrowRight/></div>
                          )
                        }
                      </div>
                    </div>
                    {
                      this.state.tabPassword === 0 ? (
                        <Form onSubmit={this.updateProfile}>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Group>
                                <label className="font-weight-bold">{GetText() ? "Nama Lengkap" : "Full Name"}</label>
                                {this.state.form_error.some(item => item === 'inputName') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                {this.state.form_error.some(item => item === 'inputNameMax') ? <span className="error-message">{GetText() ? 'Maksimal 80 karakter!' : 'Max 80 characters!'}</span> : ''}
                                <input className="form-control" type="text" name="profile_name" value={this.state.inputName} placeholder="Enter your name" onChange={this.inputName}/>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Group>
                                <label className="font-weight-bold">{GetText() ? "No. Telepon" : "Phone"}</label>
                                {this.state.form_error.some(item => item === 'inputPhone') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                <input className="form-control" type="text" name="profile_phone" value={this.state.inputPhone} placeholder="Enter your phone" onChange={this.inputPhone}/>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Group>
                                <label className="font-weight-bold">{GetText() ? "Foto" : "Photo"}</label>
                                <div className="input-group mb-3">
                                  <div className="custom-file">
                                    <input type="file" className="custom-file-input" name="profile_photo" onChange={this.inputPhoto}/>
                                    <label className="custom-file-label" id="input-photo" htmlFor="photo">{GetText() ? "Pilih file" : "Choose file"}</label>
                                  </div>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Group>
                                <label className="font-weight-bold">{GetText() ? "Bahasa" : "Language"}</label>
                                {this.state.form_error.some(item => item === 'inputLanguage') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-check">
                                      <input className="form-check-input" type="radio" name="profile_language" value="Indonesian" onChange={this.inputLanguage} id="option-1" checked={this.state.inputLanguage === 'Indonesian'}/>
                                      <label className="form-check-label option-label" htmlFor="option-1">Bahasa Indonesia</label>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-check">
                                      <input className="form-check-input" type="radio" name="profile_language" value="English" onChange={this.inputLanguage} id="option-2" checked={this.state.inputLanguage === 'English'}/>
                                      <label className="form-check-label option-label" htmlFor="option-2">English</label>
                                    </div>
                                  </div>
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <Button className="mt-4" variant="primary" size="lg" type="submit" value="Save" id="submit">{GetText() ? "Simpan" : "Update"}</Button>
                        </Form>
                      ) : (
                        <Form onSubmit={this.updatePassword}>
                          { 
                            this.state.hasPassword === true ? (
                              <div className="row">
                                <div className="col-md-6">
                                  <Form.Group>
                                    <label className="font-weight-bold">{GetText() ? "Password Sekarang" : "Current Password"}</label>
                                    {this.state.form_error.some(item => item === 'inputPasswordCurrent') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                    <input className="form-control" type="password" name="password_current" value={this.state.inputPasswordCurrent} onChange={this.inputPasswordCurrent} autoComplete='false'/>
                                  </Form.Group>
                                </div>
                              </div>  
                            ) : ''
                          }
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Group>
                                <label className="font-weight-bold">{GetText() ? "Password Baru" : "New Password"}</label>
                                {this.state.form_error.some(item => item === 'inputPasswordNew') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                {this.state.form_error.some(item => item === 'length') ? <span className="error-message">{GetText() ? 'Minimal 6 karakter!' : 'Min 6 characters!'}</span> : ''}
                                {this.state.form_error.some(item => item === 'same') ? <span className="error-message">{GetText() ? 'Password tidak cocok!' : 'Passwords don\'t match!'}</span> : ''}
                                <input className="form-control" type="password" name="password_new" value={this.state.inputPasswordNew} onChange={this.inputPasswordNew} autoComplete='false'/>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Group>
                                <label className="font-weight-bold">{GetText() ? "Konfirmasi Password Baru" : "Confirm New Password"}</label>
                                {this.state.form_error.some(item => item === 'inputPasswordConfirm') ? <span className="error-message">{GetText() ? 'Wajib diisi!' : 'Required!'}</span> : ''}
                                {this.state.form_error.some(item => item === 'notsame') ? <span className="error-message">{GetText() ? 'Password tidak cocok!' : 'Password doesn\'t match!'}</span> : ''}
                                <input className="form-control" type="password" name="password_confirm" value={this.state.inputPasswordConfirm} onChange={this.inputPasswordConfirm} autoComplete='false'/>
                              </Form.Group>
                            </div>
                          </div>
                          {this.state.form_error.some(item => item === 'invalid') ? <p className="error-message d-block mb-2 float-none">{GetText() ? "Username atau password tidak ditemukan" : "Invalid username or password"}</p> : ''}
                          {this.state.form_success.some(item => item === 'updated') ? <p className="success-message d-block mb-2 float-none">{GetText() ? "Password berhasil diubah" : "Password has been updated"}</p> : ''}
                          <Button className="mt-4" variant="primary" size="lg" type="submit" value="Save" id="submit">{GetText() ? "Simpan" : "Update"}</Button>
                        </Form>
                      )
                    }
                  </Tab.Pane>
                  <Tab.Pane eventKey="subscription">
                    <h3 className="mb-4">{GetText() ? "Berlangganan" : "Subscription"}</h3>
                    <div className="table-wrapper">
                      {
                        this.state.subscriptionList.length > 0 ? (
                          <table className="table table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <th width="50">No.</th>
                                <th>{GetText() ? "Tipe" : "Type"}</th>
                                <th width="150">{GetText() ? "Pembayaran" : "Payment"}</th>
                                <th width="100">{GetText() ? "Jumlah" : "Amount"}</th>
                                <th width="50">Detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              { 
                                this.state.subscriptionList
                                .map((Subscription_Item, i) => {
                                  var orderid = "", subscribezId = ""; 
                                  if(Subscription_Item.subcontent.orderId !== undefined && Subscription_Item.subcontent.orderId !== null) { orderid = Subscription_Item.subcontent.orderId; }
                                  if(Subscription_Item.subdocid !== undefined && Subscription_Item.subdocid !== null) { subscribezId = Subscription_Item.subdocid+orderid.substring(2,orderid.length); }
                                  return (
                                    <tr key={"subscription-list-" + i}>
                                      <td>{(i + 1)}.</td>
                                      <td>
                                        {Subscription_Item.subcontent.subscribePlan}
                                        <br/>
                                        <small>{Subscription_Item.subcontent.dateEnd ? (GetText() ? "Berakhir pada tanggal " : "Expired on ") + (Moment.unix(Subscription_Item.subcontent.dateEnd._seconds).format('D MMMM Y')) : ('')}</small>
                                      </td>
                                      <td>
                                        {Subscription_Item.subcontent.paymentMethod}
                                        {
                                          Subscription_Item.subcontent.paymentMethod === 'Virtual Account' ? (
                                          <>
                                            <br/><small>{Subscription_Item.subcontent.paymentInfo.replace('<br/>', ' ')}</small>
                                          </>
                                          ) : <></>
                                        }
                                        
                                      </td>
                                      <td>
                                        {(Subscription_Item.subcontent.paymentCurrency ? (Subscription_Item.subcontent.paymentCurrency === "IDR" ? "Rp " : "$") : "Rp ") + new Intl.NumberFormat().format(Subscription_Item.subcontent.subscribePrice)}
                                        <br/>
                                        {
                                          Subscription_Item.subcontent.subscribeStatus === global.config.subscribeStatus[0] ? (
                                            Subscription_Item.subcontent.paymentConfirmationDate ? (
                                              <Badge variant="info">{GetText() ? "Memverifikasi" : "Verifying"}</Badge>
                                            ) : (
                                              <Badge variant="warning">{GetText() ? "Belum Dibayar" : "Unpaid"}</Badge>
                                            )
                                          ) : Subscription_Item.subcontent.subscribeStatus === global.config.subscribeStatus[1] ? (
                                            <Badge variant="success">{GetText() ? "Lunas" : "Paid"}</Badge>
                                          ) : Subscription_Item.subcontent.subscribeStatus === global.config.subscribeStatus[3] ? (
                                              <Badge variant="secondary">{GetText() ? "Dibatalkan" : "Cancel"}</Badge>
                                          ) : (
                                            <Badge variant="secondary">{GetText() ? "Expired" : "Expired"}</Badge>
                                          )
                                        }
                                      </td>
                                      <td className="text-center">
                                        <a className="text-secondary" href={"/invoice/subscription/" + subscribezId}><FaFileAlt/></a>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        ) : (
                          this.state.member.memberPlan === 'Member' ? (
                            <div>
                              <p className="mt-4">{GetText() ? "Anda berlangganan hingga" : "You are subscribed until" } {Moment.unix(this.state.member.expiredSubscribeMember._seconds).format('D MMMM Y')}.</p>
                            </div>
                          ) : ( 
                            <div>
                              <p className="mt-4">{GetText() ? "Belum berlangganan" : "No active subscription"}</p>
                              <a className="btn btn-primary btn-lg" href="/subscribe">{GetText() ? "Berlangganan sekarang" : "Subscribe Now!"}</a>
                            </div>
                          )
                        )
                      }
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="mycourses">
                    <h3 className="mb-4">{GetText() ? "Workshop" : "My Workshops"}</h3>
                    <div className="table-wrapper">
                      {
                        this.state.purchaseList.length > 0 ? (
                          <table className="table table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <th width="50">No.</th>
                                <th>Workshop</th>
                                <th width="150">{GetText() ? "Tanggal" : "Date"}</th>
                                <th width="100">{GetText() ? "ID Tiket" : "Ticket ID"}</th>
                                <th width="50">Detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.purchaseList.map((Purchase_Item, i) => {
                                  return (
                                    <tr key={"purchase-list-" + i}>
                                      <td>{(i + 1)}.</td>
                                      <td>
                                        <a className="text-dark" href={'/course/' + Purchase_Item.subcontent.courseId}>{Purchase_Item.subcontent.courseTitle}</a>
                                      </td>
                                      <td>
                                        {Purchase_Item.subcontent.paymentMethod}
                                        {
                                          Purchase_Item.subcontent.paymentMethod === 'Virtual Account' ? (
                                            <>
                                            <br/><small>{Purchase_Item.subcontent.paymentInfo.replace('<br/>', ' ')}</small>
                                            </>
                                          )  : <></>
                                        }
                                      </td>
                                      <td>
                                        {(Purchase_Item.subcontent.paymentCurrency ? (Purchase_Item.subcontent.paymentCurrency === "IDR" ? "Rp " : "$") : "Rp ") + new Intl.NumberFormat().format(Purchase_Item.subcontent.purchasePrice)}
                                        <br/>
                                        {
                                          Purchase_Item.subcontent.purchaseStatus === 'New Purchase' ? (
                                            Purchase_Item.subcontent.paymentConfirmationDate ? (
                                              <Badge variant="info">{GetText() ? "Memverifikasi" : "Verifying"}</Badge>
                                            ) : (
                                              <Badge variant="warning">{GetText() ? "Belum Dibayar" : "Unpaid"}</Badge>
                                            )
                                          ) : Purchase_Item.subcontent.purchaseStatus === 'Active' ? (
                                            <Badge variant="success">{GetText() ? "Lunas" : "Paid"}</Badge>
                                          ) : Purchase_Item.subcontent.purchaseStatus === 'Cancel' ? (
                                              <Badge variant="secondary">{GetText() ? "Dibatalkan" : "Cancel"}</Badge>
                                          ) : (
                                            <Badge variant="secondary">{GetText() ? "Expired" : "Expired"}</Badge>
                                          )

                                          // ) : (
                                          //   <Badge variant="success">{GetText() ? "Lunas" : "Paid"}</Badge>
                                          // )
                                        }
                                      </td>
                                      <td className="text-center">
                                        <a className="text-secondary" href={"/invoice/course/" + Purchase_Item.subdocid}><FaFileAlt/></a>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        ) : ( 
                          <div>
                            <p className="mt-4">{GetText() ? "Belum ikut workshop" : "No workshops"}</p>
                            <a className="btn btn-primary btn-lg" href="/courses">{GetText() ? "Ikut Workshop" : "Browse Workshops"}</a>
                          </div>
                        )
                      }
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="mylibraries">
                    <h3 className="mb-4">{GetText() ? "Library" : "My Library"}</h3>
                    <div className="table-wrapper">
                      {
                        this.state.enrollList.length > 0 ? (
                          <table className="table table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <th width="50">No.</th>
                                <th>Library</th>
                                <th width="150">{GetText() ? "Terakhir Dilihat" : "Last Visited"}</th>
                                <th width="100">{GetText() ? "Modul Terakhir" : "Last Module"}</th>
                                <th width="50">{GetText() ? "Perkembangan" : "Progress"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.enrollList.map((Enroll_Item, i) => {
                                  return (
                                    <tr key={"enroll-list-" + i}>
                                      <td>{(i + 1)}.</td>
                                      <td><a className="text-dark" href={'/enroll/' + Enroll_Item.subcontent.libraryId}>{Enroll_Item.subcontent.libraryTitle}</a></td>
                                      <td>{Moment(Enroll_Item.subcontent.lastVisited).format('D MMMM Y')}</td>
                                      <td>{Enroll_Item.subcontent.lastLesson > 0 ? (GetText() ?  'Modul ' : 'Module ') + Enroll_Item.subcontent.lastLesson : (GetText() ?  'Pengantar ' : 'Introduction')}</td>
                                      <td className="text-center">{Enroll_Item.subcontent.completion + '%'}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        ) : ( 
                          <div>
                            <p className="mt-4">{GetText() ? "Belum ada library" : "No library."}</p>
                            <a className="btn btn-primary btn-lg" href="/libraries">{GetText() ? "Lihat library" : "Browse Library"}</a>
                          </div>
                        )
                      }
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="mycertificates">
                    <h3 className="mb-4">{GetText() ? "Sertifikat" : "My Certificate"}</h3>
                    <div className="table-wrapper">
                      {
                        this.state.certificateList.length > 0 ? (
                          <table className="table table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <th width="50">No.</th>
                                <th>{GetText() ? "Judul" : "Title"}</th>
                                <th width="150">{GetText() ? "Dibuat" : "Created"}</th>
                                <th width="50">{GetText() ? "Skor" : "Score"}</th>
                                <th width="50">{GetText() ? "Unduh" : "Download"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.certificateList.map((Certificate_Item, i) => {
                                  return (
                                    <tr key={"enroll-list-" + i}>
                                      <td>{(i + 1)}.</td>
                                      <td>
                                        <a className="text-dark" href={(Certificate_Item.subcontent.courseType === 'library' ? '/enroll/' : '/course/') + Certificate_Item.subcontent.courseId}>{Certificate_Item.subcontent.courseTitle}</a>
                                        <br/>
                                        <small class="text-capitalize">{Certificate_Item.subcontent.courseType}</small>
                                      </td>
                                      <td>{Moment.unix(Certificate_Item.subcontent.fileDate._seconds).format('D MMMM Y')}</td>
                                      <td className="text-center">{Certificate_Item.subcontent.score}</td>
                                      <td className="text-center">
                                        <a className="text-secondary" href={Certificate_Item.subcontent.fileUrl} target="_blank" rel="noreferrer" download><FaDownload/></a>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        ) : ( 
                          <div>
                            <p className="mt-4">{GetText() ? "Belum ada sertifikat" : "No certificate"}</p>
                            <a className="btn btn-primary btn-lg" href="/libraries">{GetText() ? "Lihat Library" : "Browse Library"}</a>
                          </div>
                        )
                      }
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Container>
      </div>
    );
  }
}