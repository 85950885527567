import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Moment from 'moment';
import { FaDownload } from 'react-icons/fa';
import { GetText } from '../helper';
import defPicProfile from '../img/pic-profile-sq.gif';
import '../config/global';

class SettingsPopup extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            memberName: '',
            memberEmail: '',
            memberPhone: '',
            memberLanguage: '',
            memberPicture: '',
            memberInfo: [],
            libraryEnrollList: [],
            certificateList: []
        }
    }
     
    componentDidMount() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        var memberInfo=othersContent.sendcontent.content[0].maincontent;
        var mFullName = memberInfo.memberName; if(mFullName === undefined) { mFullName = ""; }
        var mPhone = memberInfo.memberPhone; if(mPhone === undefined) { mPhone = ""; }
        var mLanguage = memberInfo.memberLanguage; if(mLanguage === undefined) { mLanguage = ""; }
        var mPicture = memberInfo.memberPicture; if(mPicture === undefined || mPicture === "") { mPicture = defPicProfile; }
        var mEmail = memberInfo.memberEmail; if(mEmail === undefined) { mEmail = ""; }
        this.setState({
            stPopTitle: '',
            memberName: mFullName,
            memberEmail: mEmail,
            memberPhone: mPhone,
            memberLanguage: mLanguage,
            memberPicture: mPicture,
            memberInfo: memberInfo,
            libraryEnrollList: othersContent.sendcontent.subcontent3,
            certificateList: othersContent.sendcontent.subcontent4
        }); 
    }
    enrollLibrary() {
      var enrollLibList = [];
      if(this.state.libraryEnrollList.length > 0) {
        for(var ls=0; ls<this.state.libraryEnrollList.length; ls++) {
          var enrollItem = this.state.libraryEnrollList[ls];
          var certificateStat = "-";
          if(this.state.certificateList.length > 0) {
            for(var ct=0; ct<this.state.certificateList.length; ct++) {
              var certData = this.state.certificateList[ct].subcontent;
                if (certData.courseType === "library" && certData.courseId === enrollItem.subcontent.libraryId) {
                  certificateStat = <a className="text-secondary" href={certData.fileUrl} target="_blank" rel="noreferrer" download><FaDownload/></a>;
                }
            }
          }
          
          enrollLibList.push(
            <tr key={"enroll-list-" + ls}>
              <td>{(ls + 1)}.</td>
              <td><a className="text-dark" href={'/enroll/' + enrollItem.subcontent.libraryId}>{enrollItem.subcontent.libraryTitle}</a></td>
              <td>{Moment(enrollItem.subcontent.lastVisited).format('D MMMM Y')}</td>
              <td>{enrollItem.subcontent.lastLesson > 0 ? (GetText() ?  'Modul ' : 'Module ') + enrollItem.subcontent.lastLesson : (GetText() ?  'Pengantar ' : 'Introduction')}</td>
              <td className="text-center">{enrollItem.subcontent.completion + '%'}</td>
              <td className="text-center">{certificateStat}</td>
            </tr>
          )
        }
      }
      return enrollLibList;
    }
    render() {
      var viewEnrollLibrary = this.enrollLibrary();
      return (
        <div className="mgT20">
              <div className="row py-3 mb-5">
                <div className="col-md-2"><img className="rounded-circle" src={this.state.memberPicture} alt="Avatar" width={80}/></div>
                <div className="col-md-10 align-self-center">
                  <div>
                    <h3 className="st3Title">{this.state.memberName}</h3>
                    <h6 className="text-secondary">{this.state.memberEmail}</h6>
                  </div>
                </div>
              </div>

              <section>
                <Container>
                  <h4>{GetText() ? "Library yang Diikuti" : "Enrolled Library"}</h4>
                  <div className="table-wrapper">
                      {
                        this.state.libraryEnrollList.length > 0 ? (
                          <table className="table table-striped">
                            <thead className="thead-dark">
                              <tr>
                                <th width="50">No.</th>
                                <th>Library</th>
                                <th width="150">{GetText() ? "Terakhir Dilihat" : "Last Visited"}</th>
                                <th width="100">{GetText() ? "Modul Terakhir" : "Last Module"}</th>
                                <th width="50">{GetText() ? "Perkembangan" : "Progress"}</th>
                                <th width="50">{GetText() ? "Sertifikat" : "Certificate"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {viewEnrollLibrary}
                            </tbody>
                          </table>
                        ) : ( 
                          <div>
                            <p className="mt-4 text-center">{GetText() ? "Belum ada data" : "No data."}</p>
                          </div>
                        )
                      }
                    </div>
                </Container>
              </section>
              
        </div>
      );
    }
 }
  
export default SettingsPopup;

