import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

export default class Success extends Component {
  render() {
    return (
      <div className="success">
        <Container>
          <h1 className="mb-4">Success</h1>
        </Container>
      </div>
    );
  }
}